import { Box, Flex, Heading, Text } from "@chakra-ui/react";

const Privacy = () => {
  return (
    <Flex direction="column" align="center" p={8} bg="gray.50" minH="100vh">
      <Box maxW="800px" bg="white" p={8} shadow="lg" borderRadius="md">
        <Heading as="h2" size="lg" mb={4}>
          ibt-practice 개인정보처리방침
        </Heading>
        {/* 서비스 개요 */}
        <Heading as="h2" size="md" mb={4}>
          1. 개인정보 처리방침
        </Heading>
        <Text mb={6}>
          개인정보 처리방침은 허남현(이하 “서비스 제공자”라 합니다)가
          ibt-practice 서비스를 제공함에 있어, 개인정보를 어떻게
          수집·이용·보관·파기하는지에 대한 정보를 담은 방침을 의미합니다.
          개인정보 처리방침은 개인정보보호법 등 국내 개인정보 보호 법령을 모두
          준수하고 있습니다. 본 개인정보 처리방침에서 정하지 않은 용어의 정의는
          서비스 이용약관을 따릅니다.
        </Text>

        {/* 계정 생성 및 관리 */}
        <Heading as="h2" size="md" mb={4}>
          2. 수집하는 개인정보의 항목
        </Heading>
        <Text mb={6}>
          서비스 제공자는 서비스 제공을 위해 다음 항목 중 최소한의 개인정보를
          수집합니다.
          <Text>
            (1) 회원가입 시 수집되는 개인정보 : 이메일, 닉네임, 비밀번호
          </Text>
          <Text>
            (2) 서비스 이용 과정에서 자동으로 수집되는 정보 : 기기 정보(유저
            에이전트), 이용 기록(작성한 답안지), 로그 기록(IP 주소, 접속 시간)
          </Text>
        </Text>

        {/* 개인정보 수집 및 사용 */}
        <Heading as="h2" size="md" mb={4}>
          3. 수집한 개인정보의 처리 목적
        </Heading>
        <Text mb={6}>
          수집된 개인정보는 다음의 목적에 한해 이용됩니다.{" "}
          <Text> - 가입 및 탈퇴 의사 확인, 회원 식별 및 관리 </Text>{" "}
          <Text>- 회원관리, 서비스 운영 및 유지보수를 위한 통계 자료 도출</Text>
        </Text>

        {/* 사용자의 의무 */}
        <Heading as="h2" size="md" mb={4}>
          4. 개인정보의 처리 및 보유 기간
        </Heading>
        <Text mb={6}>
          서비스 제공자는 법령에 따른 개인정보 보유 및 이용기간 또는
          회원으로부터 개인정보를 수집 시에 동의 받은 개인정보의 보유 및
          이용기간 내에서 개인정보를 처리 및 보유합니다. 각각의 개인정보 처리 및
          보유 기간은 다음과 같습니다.{" "}
          <Text>- ibt-practice 회원 식별 및 관리 : 회원 탈퇴까지 </Text>
          <Text>- 로그 기록 : 1년 </Text>
          <Text> - 기기 정보 : 1년</Text>
        </Text>

        {/* 서비스 제한 및 중단 */}
        <Heading as="h2" size="md" mb={4}>
          5. 개인정보의 제3자 제공
        </Heading>
        <Text mb={6}>
          서비스 제공자는 개인정보의 처리 목적에서 명시한 범위 내에서만
          처리하며, 이용자의 개인정보를 제3자에게 제공하지 않습니다.
        </Text>

        {/* 책임 제한 */}
        <Heading as="h2" size="md" mb={4}>
          6. 개인정보의 파기
        </Heading>
        <Text mb={6}>
          서비스 제공자는 탈퇴 및 동의 철회 등 개인정보 수집 및 이용 목적이
          달성될 경우 지체없이 해당 개인정보를 파기 합니다. 서비스 제공자는 위와
          같이 파기 사유가 발생한 개인정보는 지체없이 재생이 불가능한 방법으로
          파기합니다. 전자적 파일 형태로 기록·저장된 개인정보는 기록을 재생할 수
          없도록 파기합니다.
        </Text>
        <Heading as="h2" size="md" mb={4}>
          7. 정보주체의 권리, 의무 및 행사
        </Heading>
        <Text mb={6}>
          회원은 언제든지 자신의 개인정보를 조회하거나 수정, 삭제, 탈퇴 할 수
          있으며, 이메일(ibtpracticehelp@gmail.com)을 통해
          열람∙정정∙삭제∙처리정지 요구 등의 권리를 행사할 수 있습니다. 이에 대해
          서비스 제공자는 지체없이 조치하겠습니다.
        </Text>
        <Heading as="h2" size="md" mb={4}>
          8. 개인정보의 안정성 확보조치
        </Heading>
        <Text mb={4}>
          서비스 제공자는 개인정보의 안전성 확보를 위해 개인정보처리시스템 등의
          접근 권한 관리와 개인정보의 암호화와 같은 조치를 취하고 있습니다.
        </Text>
        <Heading as="h2" size="md" mb={4}>
          9. 개인정보에 관한 책임자 및 서비스
        </Heading>
        <Text>
          서비스 제공자는 개인정보 처리에 관한 업무를 총괄해서 책임지고,
          개인정보 처리와 관련한 회원의 불만처리 및 피해구제, 열람청구 등을
          위하여 개인정보 보호책임자를 지정하고 운영하고 있습니다.
        </Text>
        <Text mb={2}>개인정보 보호책임자</Text>
        <Text>성명 : 허남현 </Text>
        <Text>이메일 : ibtpracticehelp@gmail.com</Text>
        <Text mb={2}>
          기타 개인정보 침해에 대한 신고나 상담이 필요하신 경우에는 다음 기관에
          문의하시기 바랍니다.
        </Text>
        <Text> * 개인정보 분쟁조정위원회 : https://www.kopico.go.kr </Text>
        <Text>
          * 개인정보침해신고센터 : (국번없이) 118 (https://privacy.kisa.or.kr)
        </Text>
        <Text> * 사이버범죄 신고시스템 : https://ecrm.police.go.kr</Text>
      </Box>
    </Flex>
  );
};

export default Privacy;
