import React, { useState } from "react";
import {
  Box,
  Button,
  Input,
  FormControl,
  FormLabel,
  Heading,
  Flex,
  Tooltip,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom"; // 라우팅을 위한 useNavigate 훅
import { fetchUserInfo, LogIn } from "../api";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate(); // 페이지 이동을 위한 useNavigate 사용

  const handleLogin = async () => {
    try {
      const data = await LogIn(email, password);
      console.log(data);
      // JWT 토큰을 로컬 스토리지에 저장
      localStorage.setItem("access_token", data.access);
      localStorage.setItem("refresh_token", data.refresh);
      const userData = await fetchUserInfo();
      localStorage.setItem("nickname", userData.nickname);
      if (userData.agreed_to_terms == false) {
        navigate("/agreement_retroactive");
      } else {
        // 로그인 성공 후 페이지 이동
        navigate("/answers");
      }
    } catch (error) {
      window.confirm(
        "로그인에 실패했습니다. 이메일과 비밀번호를 다시 확인해주세요"
      );
    }
  };

  const handleGuestUse = () => {
    // 로그인을 하지 않고 /answers로 라우팅
    navigate("/answers/new");
  };

  const handleSignup = () => {
    // 회원가입 페이지로 이동
    navigate("/agreement");
  };

  return (
    <Flex align="center" justify="center" height="100vh">
      <Box p="6" rounded="md" shadow="md" width="400px" bg="white">
        <Heading as="h2" mb="6" textAlign="center">
          ibt 연습장 로그인
        </Heading>

        {/* 이메일 입력 */}
        <FormControl mb="4">
          <FormLabel>이메일</FormLabel>
          <Input
            type="email"
            placeholder="이메일 입력"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </FormControl>

        {/* 비밀번호 입력 */}
        <FormControl mb="4">
          <FormLabel>비밀번호</FormLabel>
          <Input
            type="password"
            placeholder="비밀번호 입력"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </FormControl>

        {/* 로그인 버튼 */}
        <Button colorScheme="blue" width="full" mb="4" onClick={handleLogin}>
          로그인
        </Button>

        {/* 회원가입 이동 버튼 */}
        <Button colorScheme="teal" width="full" mb="4" onClick={handleSignup}>
          회원가입
        </Button>

        {/* 로그인을 하지 않고 사용하기 버튼 */}
        <Tooltip
          label="로그인 하면 답안지를 저장할 수 있어요!"
          aria-label="Tooltip"
        >
          <Button
            colorScheme="green"
            width="full"
            mb="4"
            onClick={handleGuestUse}
          >
            로그인 없이 사용하기
          </Button>
        </Tooltip>
        <Button
          colorScheme="yellow"
          width="full"
          onClick={() =>
            window.open(
              "https://petite-efraasia-eeb.notion.site/ibt-1059ca58bc908067ae5cc9fb69fd2bf6",
              "_blank",
              "noopener, noreferrer"
            )
          }
        >
          설명 보러 가기
        </Button>
        <Flex fontSize="sm" color="gray.600" mt="4" justifyContent="center">
          <Button onClick={() => navigate("/policy")} variant={"ghost"}>
            이용약관
          </Button>
          <Box width={"120px"} />
          <Button onClick={() => navigate("/privacy")} variant={"ghost"}>
            개인정보 처리방침
          </Button>
        </Flex>
      </Box>
    </Flex>
  );
};

export default Login;
