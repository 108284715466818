import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { useEffect, useState } from "react";
import { jwtDecode } from "jwt-decode"; // Correct import of jwtDecode
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import AnswerList from "./pages/AnswerList";
import Answer from "./pages/Answer";
import View from "./pages/View";
import Policy from "./pages/Policy";
import Privacy from "./pages/Privacy";
import Agreement from "./pages/Agreement";
import AgreeRetroActive from "./pages/AgreeRetroactive";
import { refreshAccessToken } from "./api";

const isTokenExpired = (token) => {
  try {
    const decoded = jwtDecode(token);
    const currentTime = Date.now() / 1000; // 현재 시간을 초 단위로 변환
    return decoded.exp < currentTime; // 토큰 만료 시간(exp)과 비교
  } catch (error) {
    return true; // 디코딩 실패 시 만료된 것으로 간주
  }
};

const isAuthenticated = async () => {
  const accessToken = localStorage.getItem("access_token");

  if (!accessToken) return false; // 토큰이 없으면 인증되지 않은 상태

  if (isTokenExpired(accessToken)) {
    try {
      // 리프레시 토큰으로 새로운 액세스 토큰을 발급
      const newAccessToken = await refreshAccessToken();
      return !!newAccessToken; // 새 토큰 발급 성공 여부에 따라 true/false 반환
    } catch (error) {
      console.error("Failed to refresh access token:", error);
      return false; // 토큰 갱신 실패 시 인증되지 않은 상태로 간주
    }
  }

  return true; // 토큰이 유효하면 인증된 상태로 간주
};

const PrivateRoute = ({ children }) => {
  const [authenticated, setAuthenticated] = useState(null);

  useEffect(() => {
    const checkAuth = async () => {
      const auth = await isAuthenticated();
      setAuthenticated(auth);
    };

    checkAuth();
  }, []);

  if (authenticated === null) {
    return <div>Loading...</div>; // 인증 상태 확인 중일 때 로딩 표시
  }

  return authenticated ? children : <Navigate to="/login" />;
};

const App = () => {
  return (
    <Router>
      <Routes>
        {/* Public Routes */}
        <Route path="/policy" element={<Policy />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/agreement_retroactive" element={<AgreeRetroActive />} />

        <Route path="/login" element={<Login />} />
        <Route path="/agreement" element={<Agreement />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/answers/new" element={<Answer />} />

        {/* Protected Routes */}
        <Route
          path="/answers"
          element={
            <PrivateRoute>
              <AnswerList />
            </PrivateRoute>
          }
        />

        <Route
          path="/answers/:id"
          element={
            <PrivateRoute>
              <Answer />
            </PrivateRoute>
          }
        />
        <Route
          path="/view/:id"
          element={<View />} // Assuming view is public
        />

        {/* Default route: Redirect to /answers if authenticated, otherwise to /login */}
        <Route
          path="*"
          element={
            <PrivateRoute>
              <Navigate to="/answers" />
            </PrivateRoute>
          }
        />
      </Routes>
    </Router>
  );
};

export default App;
