const baseUrl = process.env.REACT_APP_API_URL;

export const getAccessToken = () => {
  return localStorage.getItem("access_token");
};

export const getRefreshToken = () => {
  return localStorage.getItem("refresh_token");
};

export const refreshAccessToken = async () => {
  const refreshToken = getRefreshToken();

  const response = await fetch(baseUrl + "auth/token/refresh/", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      refresh: refreshToken,
    }),
  });

  if (!response.ok) {
    throw new Error("Failed to refresh access token");
  }

  const data = await response.json();
  const newAccessToken = data.access;

  // 새로운 액세스 토큰을 로컬 스토리지에 저장
  localStorage.setItem("access_token", newAccessToken);

  return newAccessToken;
};

const fetchWithToken = async (url, options) => {
  let accessToken = getAccessToken();
  let response = await fetch(url, {
    ...options,
    headers: {
      ...options.headers,
      Authorization: `Bearer ${accessToken}`,
    },
  });

  // If the token is expired (401 Unauthorized), refresh it
  if (response.status === 401) {
    try {
      const newAccessToken = await refreshAccessToken(); // Get the new access token

      // Retry the request with the new access token
      response = await fetch(url, {
        ...options,
        headers: {
          ...options.headers,
          Authorization: `Bearer ${newAccessToken}`,
        },
      });
    } catch (error) {
      console.error("Failed to refresh access token:", error);
      throw new Error("Session expired. Please log in again.");
    }
  }

  return response;
};
// api.js
export const fetchAnswers = async () => {
  const response = await fetchWithToken(baseUrl + "answers/", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });

  if (!response.ok) {
    throw new Error("Failed to fetch answers");
  }

  return await response.json();
};
export const fetchAnswer = async (id) => {
  const response = await fetchWithToken(baseUrl + `answers/${id}/`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });

  if (!response.ok) {
    throw new Error("Failed to fetch the answer");
  }

  return await response.json();
};

export const uploadAnswer = async (subject, content) => {
  const response = await fetchWithToken(baseUrl + "answers/", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      subject,
      content,
    }),
  });

  if (!response.ok) {
    throw new Error("Failed to upload answer");
  }

  return response;
};

export const modifyAnswer = async (answer_id, content) => {
  const response = await fetchWithToken(baseUrl + `answers/${answer_id}/`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ content }),
  });

  if (!response.ok) {
    throw new Error("Failed to modify answer");
  }

  return response;
};

export const updateAnswerTitle = async (answerId, newTitle) => {
  let accessToken = getAccessToken();
  let response = await fetch(baseUrl + `answers/${answerId}/`, {
    method: "PATCH",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ subject: newTitle }),
  });
  if (response.status === 401) {
    // 액세스 토큰이 만료된 경우, 리프레시 토큰을 사용해 새로운 액세스 토큰을 얻음
    try {
      accessToken = await refreshAccessToken(); // 새로 발급받은 액세스 토큰

      // 새 액세스 토큰으로 다시 요청 시도
      response = await fetch(baseUrl + `answers/${answerId}/`, {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ subject: newTitle }),
      });
    } catch (error) {
      throw new Error("Failed to refresh access token");
    }
  }

  if (!response.ok) {
    throw new Error("Failed to delete the answer");
  }
  return response;
};

export const togglePublicAccess = async (answer_id, isPublic) => {
  const response = await fetchWithToken(baseUrl + `answers/${answer_id}/`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ is_public: !isPublic }),
  });

  if (!response.ok) {
    throw new Error("Failed to toggle public access");
  }

  return await response;
};

export const deleteUser = async () => {
  const response = await fetchWithToken(baseUrl + "auth/delete/", {
    method: "DELETE",
  });

  if (!response.ok) {
    throw new Error("Failed to delete user");
  }

  return response;
};

export const updateUser = async (agreed_to_terms, agreed_to_privacy) => {
  const response = await fetchWithToken(baseUrl + "auth/update/", {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ agreed_to_terms, agreed_to_privacy }),
  });

  if (!response.ok) {
    throw new Error("Failed to update user");
  }
};

// api.js
export const deleteAnswer = async (id) => {
  let accessToken = getAccessToken();
  let response = await fetch(baseUrl + `answers/${id}/`, {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    },
  });
  if (response.status === 401) {
    // 액세스 토큰이 만료된 경우, 리프레시 토큰을 사용해 새로운 액세스 토큰을 얻음
    try {
      accessToken = await refreshAccessToken(); // 새로 발급받은 액세스 토큰

      // 새 액세스 토큰으로 다시 요청 시도
      response = await fetch(baseUrl + `answers/${id}/`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      });
    } catch (error) {
      throw new Error("Failed to refresh access token");
    }
  }

  if (!response.ok) {
    throw new Error("Failed to delete the answer");
  }
  return response;
};

export const LogIn = async (email, password) => {
  const response = await fetch(baseUrl + "auth/login/", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      email: email,
      password: password,
    }),
  });

  if (!response.ok) {
    throw new Error("Login failed");
  }

  const data = await response.json();
  return data;
};

export const fetchUserInfo = async () => {
  const response = await fetchWithToken(baseUrl + "auth/user-info/", {
    method: "GET",
  });

  if (!response.ok) {
    throw new Error("Failed to fetch user info");
  }

  return await response.json();
};

export const signUp = async (email, nickname, password) => {
  const response = await fetch(baseUrl + "auth/register/", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      email,
      nickname,
      password,
    }),
  });

  if (!response.ok) {
    throw new Error("Registration failed");
  }

  return await response.json();
};

export const fetchPublicAnswer = async (id) => {
  const response = await fetch(baseUrl + `view/${id}/`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });

  if (!response.ok) {
    throw new Error("Failed to fetch the answer");
  }
  const data = await response.json();
  return data;
};
