// src/components/ToolBar.js
import React, { useState } from "react";
import { Flex, Button, IconButton, Box } from "@chakra-ui/react";
import { CopyIcon } from "@chakra-ui/icons"; // 아이콘 추가
import {
  FaCut,
  FaUndo,
  FaAlignRight,
  FaAlignLeft,
  FaAlignCenter,
} from "react-icons/fa"; // react-icons 사용
import {
  FaMagnifyingGlassMinus,
  FaMagnifyingGlassPlus,
  FaRegPaste,
  FaChevronDown,
  FaChevronUp,
} from "react-icons/fa6";

const ToolBar = ({
  toggleSpecialChar,
  onZoomIn,
  onZoomOut,
  isPreviewMode,
  isTransitioning,
}) => {
  const handlePaste = async () => {
    try {
      const text = await navigator.clipboard.readText();
      document.execCommand("insertText", false, text); // Alternative to execCommand
      console.log("Text pasted from clipboard");
    } catch (err) {
      console.error("Failed to paste text: ", err);
    }
  };

  const [isOpen, setIsOpen] = useState(true); // ToolBar의 열림 상태 관리

  return (
    <Flex
      alignItems="center"
      justifyContents="center"
      direction="column"
      position="fixed"
      left="20px"
      top="110px"
      zIndex="1000"
      width="60px"
    >
      <IconButton
        aria-label={isOpen ? "접기" : "펼치기"}
        icon={isOpen ? <FaChevronUp /> : <FaChevronDown />}
        onClick={() => setIsOpen(!isOpen)} // 버튼 클릭 시 ToolBar 펼치기/접기
        variant="ghost"
        size="sm"
        mb="4"
      />
      {isOpen && (
        <Flex
          direction="column"
          borderRadius="15px"
          height="60%"
          bg="white"
          p="4"
          border="1px solid gray.400"
        >
          {!isPreviewMode && (
            <>
              {/* 실행 취소 */}
              <IconButton
                mb="4"
                aria-label="실행 취소"
                icon={<FaUndo />}
                onClick={() => document.execCommand("undo")}
                variant="ghost" // Optional: set button style
                size="sm" // Optional: set size to small for better fit
              />

              {/* 실행 취소의 취소 */}
              <IconButton
                mb="4"
                aria-label="실행 취소의 취소"
                icon={<FaUndo />}
                onClick={() => document.execCommand("redo")}
                sx={{
                  transform: "scaleX(-1)", // 좌우 반전
                }}
                variant="ghost" // Optional: set button style
                size="sm" // Optional: set size to small for better fit
              />

              {/* 문단 정렬: 왼쪽, 중앙, 오른쪽 */}
              <IconButton
                mb="4"
                aria-label="왼쪽 정렬"
                icon={<FaAlignLeft />}
                onClick={() => document.execCommand("justifyLeft")}
                variant="ghost" // Optional: set button style
                size="sm" // Optional: set size to small for better fit
              />

              <IconButton
                mb="4"
                aria-label="중앙 정렬"
                icon={<FaAlignCenter />}
                onClick={() => document.execCommand("justifyCenter")}
                variant="ghost" // Optional: set button style
                size="sm" // Optional: set size to small for better fit
              />

              <IconButton
                mb="4"
                aria-label="오른쪽 정렬"
                icon={<FaAlignRight />}
                onClick={() => document.execCommand("justifyRight")}
                variant="ghost" // Optional: set button style
                size="sm" // Optional: set size to small for better fit
              />

              {/* 복사 */}
              <IconButton
                mb="4"
                aria-label="복사"
                icon={<CopyIcon />}
                onClick={() => document.execCommand("copy")}
                variant="ghost" // Optional: set button style
                size="sm" // Optional: set size to small for better fit
              />

              {/* 오리기 */}
              <IconButton
                mb="4"
                aria-label="오리기"
                icon={<FaCut />}
                onClick={() => document.execCommand("cut")}
                variant="ghost" // Optional: set button style
                size="sm" // Optional: set size to small for better fit
              />

              {/* 붙여넣기 */}
              <IconButton
                mb="4"
                aria-label="붙여넣기"
                icon={<FaRegPaste />}
                onClick={handlePaste}
                variant="ghost" // Optional: set button style
                size="sm" // Optional: set size to small for better fit
              />
              {/* 기호 버튼 */}
              <Button
                mb="4"
                onClick={toggleSpecialChar}
                variant="ghost" // Optional: set button style
                size="sm" // Optional: set size to small for better fit
              >
                기호
              </Button>
            </>
          )}

          <IconButton
            mb="4"
            aria-label="확대"
            icon={<FaMagnifyingGlassPlus />}
            onClick={onZoomIn} // 상위 컴포넌트로 확대 이벤트 전달
            variant="ghost"
            size="sm"
          />

          {/* 축소 버튼 */}
          <IconButton
            mb="4"
            aria-label="축소"
            icon={<FaMagnifyingGlassMinus />}
            onClick={onZoomOut} // 상위 컴포넌트로 축소 이벤트 전달
            variant="ghost"
            size="sm"
          />
        </Flex>
      )}
    </Flex>
  );
};

export default ToolBar;
