// src/components/Editor.js
import React, { useState, useEffect } from "react";
import { Box, Text } from "@chakra-ui/react";

const Editor = React.forwardRef(
  (
    {
      specialChar,
      scale,
      isPreviewMode,
      isTransitioning,
      editorRef,
      fetchedContent,
    },
    ref
  ) => {
    const [lineNumbers, setLineNumbers] = useState([]);

    useEffect(() => {
      if (specialChar && editorRef.current) {
        const selection = window.getSelection();

        if (selection.rangeCount > 0) {
          const range = selection.getRangeAt(0); // 현재 선택된 범위(커서 위치) 가져오기

          // 특수문자를 현재 커서 위치에 삽입
          const textNode = document.createTextNode(specialChar);
          range.insertNode(textNode);

          // 삽입 후 커서를 특수문자 뒤로 이동
          range.setStartAfter(textNode);
          range.setEndAfter(textNode);
          selection.removeAllRanges();
          selection.addRange(range);
        }
      }
    }, [specialChar]);

    const lineHeight = 40; // 고정된 줄 높이 (px 단위)
    const pageLineCounts = [30, 34, 34, 34]; // 4페이지마다 줄 수 반복

    const calculateVisibleLines = () => {
      if (editorRef.current) {
        const editorHeight = editorRef.current.scrollHeight; // 전체 텍스트의 높이
        const totalLines = Math.floor(editorHeight / lineHeight); // 전체 텍스트의 줄 수 계산
        const newLineNumbers = [];
        let currentPage = 1;
        let currentPageLine = 0;
        let currentPageMaxLines = pageLineCounts[(currentPage - 1) % 4]; // 첫 페이지는 30줄

        for (let i = 0; i < totalLines; i++) {
          if (currentPageLine >= currentPageMaxLines) {
            // 다음 페이지로 넘어가기
            currentPage++;
            currentPageLine = 0;
            currentPageMaxLines = pageLineCounts[(currentPage - 1) % 4]; // 다음 페이지의 줄 수
          }
          currentPageLine++;
          newLineNumbers.push({
            page: currentPage,
            line: currentPageLine,
          });
        }

        setLineNumbers(newLineNumbers);
      }
    };

    // 텍스트가 변경될 때마다 줄 계산 실행
    useEffect(() => {
      calculateVisibleLines();
    }, []);

    useEffect(() => {
      if (fetchedContent && editorRef.current) {
        // Fetch한 데이터를 editorRef에 삽입
        editorRef.current.innerText = fetchedContent;
      }
    }, [fetchedContent]);

    return (
      <div>
        <div
          style={{
            display: "flex",
            borderRadius: "15px",
            transform: `scale(${scale})`,
            transformOrigin: "center 0",
            transition: "transform 0.2s ease",
          }}
        >
          <div style={{ width: "40px" }}>
            <div style={{ height: "80px" }} />

            <div style={{ height: "80px", width: "12px" }} />
            {lineNumbers.map((line, index) => (
              <p
                key={index}
                style={{
                  color: index % 2 === 0 ? "gray" : "transparent",
                  lineHeight: "40px",
                  fontSize: "11px",
                  margin: 0,
                }}
              >
                {line.line} {line.line === 1 && `(${line.page}쪽)`}
              </p>
            ))}
          </div>

          <div ref={ref}>
            <div style={{ height: "80px" }} />

            <div
              style={{
                height: "40px",
                backgroundColor: "#003664",
                borderRadius: "10px 10px 0px 0px",
                textAlign: "center",
              }}
            >
              <span
                style={{
                  color: "white",
                  fontWeight: "700",
                  fontSize: "24px",
                  lineHeight: "40px",
                  display: "inline-block",
                }}
              >
                문형
              </span>
            </div>

            <div
              style={{
                height: "40px",
                backgroundColor: "#E3EFFF",
                textAlign: "center",
              }}
            >
              <span
                style={{
                  color: "#003664",
                  fontWeight: "700",
                  fontSize: "24px",
                  lineHeight: "40px",
                  display: "inline-block",
                }}
              >
                사례형
              </span>
            </div>

            <div
              className="page-wrapper"
              style={{
                width: "938px",
                padding: "0 18px",
                minHeight: "5280px",
                margin: "0 auto",
                fontFamily: "Noto Sans KR",
                borderColor: "gray",
                backgroundImage: bjhStyle,
                backgroundRepeat: "no-repeat",
              }}
            >
              <div
                contentEditable={!isPreviewMode}
                spellCheck="false"
                ref={editorRef}
                onInput={calculateVisibleLines}
                className="page editor"
                style={{
                  minHeight: "5280px",
                  fontSize: "20px",
                  lineHeight: `${lineHeight}px`,
                  whiteSpace: "pre-wrap",
                  outline: "none",
                  transition: "opacity 0.3s ease, transform 0.2s ease",
                  opacity: isTransitioning ? 0 : 1,
                  WebkitFontSmoothing: "antialiased",
                  fontSmoothing: "antialiased",
                }}
              >
                <div>
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div style={{ height: "100px" }} />

        <style>
          {`
          @media print {
          * {
    -webkit-print-color-adjust: exact !important; /* 색상 조정을 정확하게 */
    color-adjust: exact !important; /* 크롬 등 다른 브라우저용 */
  }
            background-image: none !important; /* 배경 이미지 제거 */

            .printable-content {
              height: 1360px !important;
              page-break-after: always;
              background-image: none !important; /* 배경 이미지 제거 */
            }
            .printable-content:first-child {
              padding-top: 80px; /* 첫 페이지에만 위쪽 여백 설정 */
            }
            @page {
              size: A4;
              margin: 8.78mm;
            }
          }
        `}
        </style>
      </div>
    );
  }
);

const bjhStyle = `repeating-linear-gradient(
  transparent, transparent 39px, rgb(238, 238, 238) 39px, rgb(238, 238, 238) 40px
), repeating-linear-gradient(
   rgba(255, 255, 255, 1), rgba(255, 255, 255, 1) 1200px, rgba(243, 247, 255, 1) 1200px, rgba(243, 247, 255, 1) 2560px, rgba(255, 255, 255, 1) 2560px, rgba(255, 255, 255, 1) 3920px, rgba(243, 247, 255, 1) 3920px, rgba(243, 247, 255, 1) 5280px
)`;

export default Editor;
