import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  FormControl,
  Heading,
  Flex,
  Text,
  Checkbox,
} from "@chakra-ui/react";
import { deleteUser, updateUser } from "../api";

const AgreeRetroActive = () => {
  const [isTermsAccepted, setIsTermsAccepted] = useState(false); // 이용약관 동의 상태
  const [isPrivacyAccepted, setIsPrivacyAccepted] = useState(false); // 개인정보수집 동의 상태
  const [isOver14, setIsOver14] = useState(false); // 만 14세 이상 동의 상태
  const navigate = useNavigate();

  const handleContinueUsing = async () => {
    try {
      await updateUser(true, true);
      navigate("/answers");
    } catch (error) {
      console.error("Failed to update user:", error);
      // Handle error (e.g., show an error message to the user)
    }
  };

  const handleDeleteUser = async () => {
    const confirmed = window.confirm(
      "정말로 계정을 삭제하시겠습니까? 이 작업은 되돌릴 수 없습니다."
    );

    if (!confirmed) {
      // User canceled the deletion
      return;
    }
    try {
      await deleteUser(); // Call the user deletion service
      localStorage.removeItem("access_token");
      localStorage.removeItem("refresh_token");
      navigate("/login");
    } catch (error) {
      console.error("Failed to delete user:", error);
      // Handle error (e.g., show an error message to the user)
    }
  };

  return (
    <Flex align="center" justify="center" height="100vh">
      <Box p="6" rounded="md" shadow="md" width="400px" bg="white">
        <Heading as="h2" mb="6" textAlign="center">
          약관 동의
        </Heading>

        {/* 이용약관 및 개인정보 동의 */}
        <FormControl mb="4">
          <Checkbox
            isChecked={isTermsAccepted}
            onChange={(e) => setIsTermsAccepted(e.target.checked)}
          >
            <Text as="span" color="gray.600">
              서비스 이용약관 동의(필수)
            </Text>
          </Checkbox>
        </FormControl>

        <Box
          mb="4"
          height={"160px"}
          overflowY="auto"
          whiteSpace="pre-wrap"
          rounded="md"
          shadow="md"
          fontSize={"12px"}
        >
          {/* 서비스 이용약관 내용 */}
          <Text mb={2}>서비스 이용약관</Text>
          {/* 서비스 개요 */}
          <Text mb={2}>1. 서비스 개요</Text>
          <Text mb={2}>
            본 사이트는 사용자가 IBT 시험 대비를 위해 답안지를 작성하고 저장할
            수 있는 서비스를 제공합니다. 허남현(이하 서비스 제공자)는 서비스를
            지속적으로 운영하며, 사용자는 제공된 기능을 통해 개인적인 학습 및
            연습 목적으로 사이트를 이용할 수 있습니다.
          </Text>

          {/* 계정 생성 및 관리 */}
          <Text mb={2}>2. 계정 생성 및 관리</Text>
          <Text mb={2}>
            사용자는 이메일, 비밀번호, 닉네임을 제공하여 계정을 생성할 수
            있습니다. 사용자는 자신의 비밀번호를 안전하게 관리해야 하며, 계정의
            부정 사용에 대한 책임은 전적으로 사용자에게 있습니다.
          </Text>

          {/* 개인정보 수집 및 사용 */}
          <Text mb={2}>3. 개인정보 수집 및 사용</Text>
          <Text mb={2}>
            본 사이트는 회원가입 시 이메일, 비밀번호, 닉네임을 수집합니다. 또한
            서비스 이용과정이나 사업 처리 과정에서 IP Address, 쿠키, 방문 일시,
            서비스 이용 기록, 불량 이용 기록, 광고 아이디, 접속 환경이 자동으로
            수집될 수 있습니다. 수집된 개인정보는 사용자의 계정 관리 및 서비스
            제공 목적으로만 사용되며, 사용자의 동의 없이 제3자에게 제공되지
            않습니다.
          </Text>

          {/* 사용자의 의무 */}
          <Text mb={2}>4. 사용자의 의무</Text>
          <Text mb={2}>
            사용자는 본 사이트를 통해 불법적인 활동을 하지 않으며, 다른 사용자의
            권리를 침해하지 않을 것에 동의합니다.
          </Text>

          {/* 서비스 제한 및 중단 */}
          <Text mb={2}>5. 서비스 제한 및 중단</Text>
          <Text mb={2}>
            서비스 제공자는 언제든지 서비스의 일부 또는 전부를 중단하거나 특정
            사용자의 접근을 제한할 수 있는 권리를 보유합니다.
          </Text>

          {/* 책임 제한 */}
          <Text mb={2}>6. 책임 제한</Text>
          <Text>
            서비스 제공자는 기술적 오류, 서버 중단, 기타 불가항력적 사유로 인해
            발생한 손해에 대해 책임을 지지 않습니다.
          </Text>
        </Box>
        <FormControl mb="4">
          <Checkbox
            isChecked={isPrivacyAccepted}
            onChange={(e) => setIsPrivacyAccepted(e.target.checked)}
          >
            <Text as="span" color="gray.600">
              개인정보 수집 및 이용 동의(필수)
            </Text>
          </Checkbox>
        </FormControl>
        <Box
          mb="4"
          height={"160px"}
          overflowY="auto"
          whiteSpace="pre-wrap"
          rounded="md"
          shadow="md"
          fontSize={"12px"}
        >
          {/* 서비스 이용약관 내용 */}
          <Heading mb={2} size={"sm"}>
            수집하는 개인정보의 항목
          </Heading>
          {/* 서비스 개요 */}
          <Text mb={2}>
            허남현(이하 서비스 제공자)는 서비스 제공을 위해, 회원가입 시점에
            다음에 해당하는 개인정보를 수집합니다.
          </Text>
          <Text mb={2}>- 이메일, 비밀번호, 닉네임</Text>
          <Text mb={2}>
            ※ 각 항목 또는 추가적으로 수집이 필요한 개인정보 및 개인정보를
            포함한 자료는 이용자 응대 과정과 서비스 내부 알림 수단 등을 통해
            별도의 동의 절차를 거쳐 요청·수집될 수 있습니다.
          </Text>

          {/* 계정 생성 및 관리 */}
          <Heading mb={2} size={"sm"}>
            수집한 개인정보의 처리 목적
          </Heading>
          <Text mb={2}>
            회원가입 시점에 수집된 개인정보는 다음의 목적에 한해 이용됩니다.
          </Text>
          <Text mb={2}>- 가입 및 탈퇴 의사 확인, 회원 식별 및 관리</Text>
          <Text mb={2}>
            -회원관리, 서비스 운영 및 유지보수를 위한 통계 자료 도출{" "}
          </Text>

          {/* 개인정보 수집 및 사용 */}
          <Heading mb={2} size={"sm"}>
            개인정보의 보유 및 이용기간
          </Heading>
          <Text mb={2}>
            서비스 제공자는 서비스를 제공하는 동안 개인정보 처리방침 및 관련법에
            의거하여 회원의 개인정보를 지속적으로 관리 및 보관합니다. 
            <Heading as="h3" size={"sm"} mb={"2"}>
              탈퇴 및 동의 철회 등 개인정보 수집 및 이용 목적이 달성될 경우
              수집된 개인정보는 즉시 파기하고 있습니다.
            </Heading>
            <Heading as="h3" size={"sm"} mb="2">
              아래의 정보는 수집 시점으로부터 최대 1년 간 보관 후 파기합니다.
            </Heading>
            <Heading as="h3" size={"sm"}>
              - 로그 기록{" "}
            </Heading>
            <Heading as="h3" size={"sm"}>
              - 기기 정보{" "}
            </Heading>
          </Text>

          {/* 사용자의 의무 */}
          <Text mb={2}>
            ※ 서비스 제공자는 위와 같이 파기 사유가 발생한 개인정보는 지체없이
            재생이 불가능한 방법으로 파기됩니다. 전자적 파일 형태로 기록·저장된
            개인정보는 기록을 재생할 수 없도록 파기합니다.※ 위 내용에도 불구하고
            법령에 의해 개인정보를 보존해야 하는 경우, 해당 개인정보는
            물리적·논리적으로 분리하여 해당 법령에서 정한 기간에 따라
            저장합니다. 기타 개인정보 수집 및 이용에 동의하지 않을 권리가
            있으며, 거부할 경우 회원가입이 불가합니다. 자세한 내용은 웹사이트
            하단에 게시된 개인정보처리방침을 참고하시기 바랍니다
          </Text>
        </Box>

        <FormControl mb="4">
          <Checkbox
            isChecked={isOver14}
            onChange={(e) => setIsOver14(e.target.checked)}
          >
            <Text as="span" color="gray.600">
              만 14세 이상입니다.(필수)
            </Text>
          </Checkbox>
        </FormControl>

        {/* 회원가입 버튼 */}
        <Button
          colorScheme="blue"
          width="full"
          mb="4"
          onClick={handleContinueUsing}
          isDisabled={!isTermsAccepted || !isPrivacyAccepted || !isOver14}
        >
          동의하고 서비스 계속 이용하기
        </Button>

        {/* 로그인 페이지로 이동 */}
        <Button colorScheme="gray" width="full" onClick={handleDeleteUser}>
          회원탈퇴하기
        </Button>
        {/* 이용약관 및 개인정보 처리방침 */}
      </Box>
    </Flex>
  );
};

export default AgreeRetroActive;
