// src/components/SpecialCharacters.js
import React from "react";
import { Button, Box } from "@chakra-ui/react";

const SpecialCharacters = ({ onCharacterClick }) => {
  const specialChars = [
    "①",
    "②",
    "③",
    "④",
    "⑤",
    "⑥",
    "⑦",
    "⑧",
    "⑨",
    "⑩",
    "Ⅰ",
    "Ⅱ",
    "Ⅲ",
    "Ⅳ",
    "Ⅴ",
    "Ⅵ",
    "Ⅶ",
    "Ⅷ",
    "Ⅸ",
    "Ⅹ",
    "ⅰ",
    "ⅱ",
    "ⅲ",
    "ⅳ",
    "ⅴ",
    "ⅵ",
    "ⅶ",
    "ⅷ",
    "ⅸ",
    "ⅹ",
    "「",
    "」",
    "『",
    "』",
    "§",
    "甲",
    "乙",
    "丙",
    "丁",
    "戊",
    "己",
  ];

  return (
    <Box>
      {specialChars.map((char) => (
        <Button
          padding="0px"
          border="0"
          key={char}
          onClick={() => onCharacterClick(char)}
          variant="outline"
        >
          {char}
        </Button>
      ))}
    </Box>
  );
};

export default SpecialCharacters;
