import React, { useState, useEffect } from "react";
import { Text, Flex, Box, IconButton } from "@chakra-ui/react";
import { FaRegClock } from "react-icons/fa";

const Timer = ({ timeLimitOption, timeLimit }) => {
  const [timeLeft, setTimeLeft] = useState(0); // 타이머 기본값
  const [isCumulative, setIsCumulative] = useState(false); // 누적 타이머 상태

  useEffect(() => {
    // 타이머 초기화
    if (timeLimitOption === "set") {
      const [hours, minutes] = timeLimit.split(":").map(Number);
      const totalSeconds = hours * 3600 + minutes * 60;
      setTimeLeft(totalSeconds);
    } else if (timeLimitOption === "cumulative") {
      setIsCumulative(true);
      setTimeLeft(0); // 누적 시간은 0부터 시작
    }

    // 타이머 작동 로직
    if (timeLimitOption === "set" || timeLimitOption === "cumulative") {
      const intervalId = setInterval(() => {
        setTimeLeft((prevTime) => {
          if (timeLimitOption === "set") {
            return prevTime > 0 ? prevTime - 1 : 0; // 제한 시간 타이머
          } else if (timeLimitOption === "cumulative") {
            return prevTime + 1; // 누적 타이머
          }
          return prevTime;
        });
      }, 1000);

      return () => clearInterval(intervalId); // 컴포넌트가 사라질 때 타이머 정리
    }
  }, [timeLimitOption, timeLimit]);

  // 시, 분, 초 형식으로 시간을 포맷팅
  const formatTime = (seconds) => {
    const hrs = Math.floor(seconds / 3600);
    const min = Math.floor((seconds % 3600) / 60);
    const sec = seconds % 60;
    return `${hrs < 10 ? "0" : ""}${hrs}:${min < 10 ? "0" : ""}${min}:${
      sec < 10 ? "0" : ""
    }${sec}`;
  };

  return (
    <Flex
      borderRadius="10px"
      bgColor="white"
      padding="2px 20px"
      alignItems="center"
      width={"220px"}
    >
      <IconButton
        aria-label="시간"
        icon={<FaRegClock />}
        variant="ghost"
        size="sm"
      />
      {timeLimitOption === "cumulative" ? (
        <Text> 걸린 시간 </Text>
      ) : (
        <Text> 남은 시간 </Text>
      )}

      <Box width="10px" />

      {/* 타이머 출력 */}
      {timeLimitOption === "none" ? (
        <Text color="gray.500" fontWeight="bold">
          무제한
        </Text>
      ) : (
        <Text
          color={timeLimitOption === "set" ? "red.500" : "green.500"}
          fontWeight="bold"
        >
          {formatTime(timeLeft)}
        </Text>
      )}
    </Flex>
  );
};

export default Timer;
