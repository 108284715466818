import React, { useRef, useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Box, Button, Flex, Text, IconButton } from "@chakra-ui/react";
import Editor from "../components/Editor";
import ToolBar from "../components/ToolBar";
import { shortenWords } from "../utils";
import { fetchPublicAnswer } from "../api";

const View = () => {
  const { id } = useParams(); // URL에서 answer id 또는 'new'를 가져옴
  const [answer, setAnswer] = useState(null); // 답안 상태
  const [loading, setLoading] = useState(true); // 로딩 상태
  const [error, setError] = useState(null); // 에러 상태
  const [selectedChar, setSelectedChar] = useState(""); // 선택된 특수문자
  const [isSpecialCharOpen, setIsSpecialCharOpen] = useState(false); // 특수문자 창 열기/닫기 상태
  const [isTransitioning, setIsTransitioning] = useState(false); // 전환 상태
  const editorRef = useRef();
  const textRef = useRef(null);

  const [scale, setScale] = useState(1); // 확대/축소 배율

  // 확대 처리
  const handleZoomIn = () => {
    setScale((prevScale) => Math.min(prevScale + 0.1, 2)); // 최대 2배 확대
  };

  // 축소 처리
  const handleZoomOut = () => {
    setScale((prevScale) => Math.max(prevScale - 0.1, 0.5)); // 최소 0.5배 축소
  };

  // 특수문자 창 토글 함수
  const toggleSpecialChar = () => {
    setIsSpecialCharOpen(!isSpecialCharOpen);
  };

  useEffect(() => {
    const fetchAnswer = async () => {
      try {
        const data = await fetchPublicAnswer(id);
        setAnswer(data); // 응답 데이터로 상태 업데이트
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false); // 로딩 완료
      }
    };

    fetchAnswer();
  }, [id]); // id가 변경될 때마다 다시 실행

  console.log(answer);

  if (!answer) {
    return <Text>Loading...</Text>;
  }

  if (error) {
    return <Text>Error: {error}</Text>;
  }

  return (
    <Flex direction="column">
      <Flex
        width="full"
        position="fixed"
        top="0"
        zIndex="1000"
        padding="20px"
        justify="space-between"
        align="center"
        backgroundColor="#00438e"
        height="60px"
      >
        <Flex gap="10px" alignItems="center">
          <Text color="gold">닉네임</Text>
          <Text color="white" fontSize="24px">
            {answer.nickname}
          </Text>
        </Flex>
        <Text fontSize="36px" color="white">
          {shortenWords(answer.subject, 20)}
        </Text>
        <Box width="10px" />
      </Flex>

      <Flex align="center" justify="center" backgroundColor="gray.100">
        {/* 본문 */}
        <ToolBar
          toggleSpecialChar={toggleSpecialChar}
          onZoomIn={handleZoomIn}
          onZoomOut={handleZoomOut}
          isPreviewMode={true}
          isTransitioning={isTransitioning}
        />

        <Editor
          ref={editorRef}
          specialChar={selectedChar}
          scale={scale}
          isPreviewMode={true}
          isTransitioning={isTransitioning}
          editorRef={textRef}
          fetchedContent={answer.content} // New: empty editor, Existing: fetched content
        />
      </Flex>
    </Flex>
  );
};

export default View;
