import { Box, Flex, Heading, Text } from "@chakra-ui/react";

const Policy = () => {
  return (
    <Flex direction="column" align="center" p={8} bg="gray.50" minH="100vh">
      <Box maxW="800px" bg="white" p={8} shadow="lg" borderRadius="md">
        <Heading as="h2" size="lg" mb={4}>
          서비스 이용약관
        </Heading>
        {/* 서비스 개요 */}
        <Heading as="h2" size="md" mb={4}>
          1. 서비스 개요
        </Heading>
        <Text mb={6}>
          본 사이트는 사용자가 IBT 시험 대비를 위해 답안지를 작성하고 저장할 수
          있는 서비스를 제공합니다. 허남현(이하 서비스 제공자)는 서비스를
          지속적으로 운영하며, 사용자는 제공된 기능을 통해 개인적인 학습 및 연습
          목적으로 사이트를 이용할 수 있습니다.
        </Text>

        {/* 계정 생성 및 관리 */}
        <Heading as="h2" size="md" mb={4}>
          2. 계정 생성 및 관리
        </Heading>
        <Text mb={6}>
          사용자는 이메일, 비밀번호, 닉네임을 제공하여 계정을 생성할 수
          있습니다. 사용자는 자신의 비밀번호를 안전하게 관리해야 하며, 계정의
          부정 사용에 대한 책임은 전적으로 사용자에게 있습니다.
        </Text>

        {/* 개인정보 수집 및 사용 */}
        <Heading as="h2" size="md" mb={4}>
          3. 개인정보 수집 및 사용
        </Heading>
        <Text mb={6}>
          본 사이트는 회원가입 시 이메일, 비밀번호, 닉네임을 수집합니다. 또한
          서비스 이용과정이나 사업 처리 과정에서 IP Address, 쿠키, 방문 일시,
          서비스 이용 기록, 불량 이용 기록, 광고 아이디, 접속 환경이 자동으로
          수집될 수 있습니다. 수집된 개인정보는 사용자의 계정 관리 및 서비스
          제공 목적으로만 사용되며, 사용자의 동의 없이 제3자에게 제공되지
          않습니다.
        </Text>

        {/* 사용자의 의무 */}
        <Heading as="h2" size="md" mb={4}>
          4. 사용자의 의무
        </Heading>
        <Text mb={6}>
          사용자는 본 사이트를 통해 불법적인 활동을 하지 않으며, 다른 사용자의
          권리를 침해하지 않을 것에 동의합니다.
        </Text>

        {/* 서비스 제한 및 중단 */}
        <Heading as="h2" size="md" mb={4}>
          5. 서비스 제한 및 중단
        </Heading>
        <Text mb={6}>
          서비스 제공자는 언제든지 서비스의 일부 또는 전부를 중단하거나 특정
          사용자의 접근을 제한할 수 있는 권리를 보유합니다.
        </Text>

        {/* 책임 제한 */}
        <Heading as="h2" size="md" mb={4}>
          6. 책임 제한
        </Heading>
        <Text>
          서비스 제공자는 기술적 오류, 서버 중단, 기타 불가항력적 사유로 인해
          발생한 손해에 대해 책임을 지지 않습니다.
        </Text>
      </Box>
    </Flex>
  );
};

export default Policy;
